import React from 'react';
import {Row, Col, Switch, InputNumber,Tooltip} from 'antd';
import {Icon, Slider} from 'libs';
import {BrowserType} from 'utils/BrowserType';

export class EnableSwitch extends React.Component {
    render () {
        const {text, isEnable, onChange, publicState} = this.props;
        const multiple = {
            type: 'normal',
            multiple: 1
        };
        return (
            <Row>
                <Col {...publicState.getGrid(multiple).label}>
                    <label>{text}</label>
                </Col>
                <Switch checked={isEnable} onChange={onChange} />
            </Row>
        );
    }
}
export class VideoIcon extends React.Component {
    render () {
        const {isChoosed, isDisabled, title, iconType, onClick} = this.props; // isDisabled = 是否置灰; isChoosed = 是否选中
        const iconStyle = {
            color: isDisabled ? 'rgba(0,0,0,.25)' : (isChoosed ? '#1890FF' : ''),
            pointerEvents: isDisabled ? 'none' : 'auto'
        };
        const whenClick = (e) => { //ie9 的css无效，用js控制
            if(isDisabled) return;
            onClick(e);
        };
        return (
            <div className='icon'
                onClick={whenClick}
                style={iconStyle}
            >
                <Tooltip title={title} placement='right'>
                    <Icon type={iconType}/>
                </Tooltip>
            </div>
        );
    }
}